import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import favicon from './favicon.png';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      siteUrl: string
    }
  }
  header: any
  main: any
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }

        header: file(relativePath: { eq: "header/kcpj.png" }) {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }

        main: file(relativePath: { eq: "main/0000.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>

        <Helmet
          title={data.site.siteMetadata.title}
        >
          <link rel="icon" type="image/png" href={'../../../favicon.png'} />
          <meta name="description" content={data.site.siteMetadata.description} />
          {/* <meta name="image" content={data.main.childImageSharp.src} /> */}
          <meta property="image" content={'https://storage.googleapis.com/image-2019/0000.jpg'} />
          <meta property="og:locale" content="ja_JP" />
          <meta property="og:site_name" content={data.site.siteMetadata.title} />
          <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
          <meta property="og:title" content={data.site.siteMetadata.title} />
          <meta property="og:description" content={data.site.siteMetadata.description} />
          <meta property="og:image" content={'https://storage.googleapis.com/image-2019/0000.jpg'} />
          {/* <meta property="og:image" content={data.main.childImageSharp.src} /> */}
        </Helmet>
        {/* <Header title={data.site.siteMetadata.title} header={data.header} /> */}
        <LayoutMain>{children}</LayoutMain>
      </LayoutRoot>
    )}
  />
)

export default IndexLayout

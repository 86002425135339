import * as React from 'react'
import styled from '@emotion/styled'

import { widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  background-color: #549488;
  background-image: url("../recollection/bkg-noise.png");
  height: ${(props: ContainerCssProps) => props.heightValue};
  // min-height: 110vh;
  // max-width: ${getEmSize(widths.lg)}em;
  max-width: 500px;
`

type ContainerCssProps = {
  heightValue: string
}

interface ContainerProps {
  className?: string
  height: string
}

const Container: React.FC<ContainerProps> = ({ children, className, height }) => <StyledContainer className={className} heightValue={height}>{children}</StyledContainer>

export default Container
